body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > section {
  min-height: 100vh;
}

.App .ant-layout-content .page {
  text-align: left;
  margin: 20px 0;
}

.App-link {
  color: #09d3ac;
}

.container {
  margin: 0 auto;
  padding: 0 $gutter;
  max-width: $container-width;
}

.App > .ant-layout > .ant-layout-content {
  width: 100%;
}

.navbar {
  height: 126px;
}

h2.ant-typography,
.ant-typography h2 {
  color: $primary-color;
  font-weight: 700;
  font-size: 2rem;
}

.search-box {
  width: 80%;
  box-shadow: 2px 3px 6px -1px rgb(0, 0, 0, 0.4);
  border-radius: 4px;
}
