.footer.ant-layout-footer {
  width: 100%;
  position: static;
  bottom: 0;
  padding: 0;
  margin-top: auto;
}

.footer {
  font-size: 16px;
  .developed {
    gap: 0.75rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;

    img {
      height: 42px;
    }
  }

  .links {
    background-color: rgb(0, 71, 143);
    padding-bottom: 3rem;
    padding-top: 3rem;

    .ant-typography,
    ul {
      color: #fff;
    }

    ul {
      list-style: none;
      padding: 0;

      & li:first-child {
        font-weight: 700;
        margin-bottom: 16px;
      }

      a {
        color: #fff;
      }
    }
  }
}
