// =======================
// breakpoints
// =======================
$breakpoint-desktop: 991px;
$breakpoint-tablet: 767px;
$breakpoint-mobile: 575px;

// =======================
// layout
// =======================
$gutter: 1rem;
$container-width: 1280px;

// =======================
// header
// =======================
$header-height: 78px;

// =======================
// colors
// =======================
$primary-color: #004aad;
$primary-dark: #1c1080;
// $primary-light: #f0a167;

// $secondary-dark: #be3386;
$secondary-main: #efc400;
// $secondary-light: #fe6262;

$text-color: #252525;
$green-color: #229445;

$footer-text-color: #000;
