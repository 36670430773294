.hero-container {
  background-color: #fff;

  .partners {
    font-weight: 700;
    gap: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    & .ant-typography {
      color: rgb(107 114 128);
    }

    img {
      height: 38px;
    }

    .gov-desktop {
      display: inline;
    }

    .gov-mobile {
      display: none;
    }

    @media (max-width: $breakpoint-mobile) {
      .gov-desktop {
        display: none;
      }

      .gov-mobile {
        display: inline;
      }
    }
  }

  .help {
    background-color: $secondary-main;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;

    a {
      color: black;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

.hero-body {
  text-align: left;
  background-color: #fff;
  padding-bottom: 30px;
  padding-top: 30px;

  .hero-img {
    height: 329px;
    & > div {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
    }
  }

  h3.hero-title {
    margin-top: 0;
  }
}

h2.hero-title {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.hero-btn-primary {
  margin-right: 16px;
  margin-top: 16px;
}
