@use 'variables';

.App-menu .menu-item {
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover,
  &:focus {
    color: $primary-color !important;
  }
}

.App-logo {
  display: flex;
  margin-right: auto;

  a {
    display: inline-flex;
    height: $header-height;
    align-items: center;
    flex-basis: 170px;
    flex-grow: 0;

    img {
      height: 42px;
      width: 100%;
    }
  }
}

.App-menu {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  gap: 24px;
  list-style-type: none;
  margin-bottom: 0;
  margin-right: 1rem;
  padding-left: 0;
  text-align: center;
  white-space: nowrap;

  @media (max-width: $breakpoint-desktop) {
    margin-right: 0.8rem;
  }

  @media (max-width: $breakpoint-tablet) {
    background-color: #fff;
    display: initial;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: $header-height;
    width: 100%;
    z-index: 100;
    padding: 0;
    transition: max-height 0.3s ease;
    line-height: 48px;

    &.show {
      max-height: 500px;
    }
  }

  .menu-item {
    color: unset;
    display: inline-block;
    width: 100%;

    &.active {
      color: $primary-color !important;
    }
  }

  a.add-building {
    font-weight: 600;
  }
}

.ant-layout-header {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  height: $header-height;
  padding: 0;
  & > div {
    display: flex;
    align-items: center;

    & > .ant-btn.ant-dropdown-trigger {
      padding: 0 10px;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    z-index: 5;
    & div.overlay {
      opacity: 0;
      position: fixed;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      &.show {
        width: 100%;
        top: $header-height;
        bottom: 0;
        height: 100%;
        opacity: 1;
        z-index: 10;
      }
    }
  }
}

.App-menu-button {
  margin-left: 0.8rem;
  display: none;

  @media (max-width: $breakpoint-tablet) {
    display: initial;
  }
}

.language-btn-mobile {
  @media (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.cfr-banner {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.cfr-header-logo {
  height: 2rem;
  margin-right: 1rem;
}
