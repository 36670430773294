.map-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 4px 6px -1px;
  border-radius: 12px;
  width: 100%;
  height: 422px;
  background: grey;
}

%icon-base {
  height: 38px;
  margin: -38px 0px 0px -16px;
  width: 32px;
  cursor: pointer;
}

.user-pin-icon {
  @extend %icon-base;

  color: #4285f4;
}

.center-icon {
  @extend %icon-base;

  color: $primary-color;

  &.selected {
    color: $primary-dark;
  }
}
